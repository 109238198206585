.cookiebar {
  --cookiebar-c-dark: #{$c-white};
  --cookiebar-c-text: #{$c-white};
  --cookiebar-c-primary: #{$c-white};
  --cookiebar-c-light: #{$c-black};

  z-index: 1;
  overflow-y: hidden;
  margin-left: 0;
  font-size: 1.3rem;
  line-height: 1.76rem;
  border: 1px transparentize($c-white, 0.75) solid;

  &.is-fullscreen {
    background: transparent;
  }
}

.cookiebar-controls {
  background: $c-black;
  opacity: 0;

  @include mq($from: m) {
    @include center-x;
  }
}

.cookiebar__actions {
  flex-direction: column-reverse;
  text-align: left;

  [class*='cookiebar__action--'] {
    margin-bottom: 10px;

    @include mq($until: xs) {
      align-self: flex-start;
    }
  }

  @include mq($until: xs) {
    align-items: flex-start;
  }

  @include mq($from: m) {
    flex-direction: row;
  }
}

.cookiebar__header {
  padding: 3rem 2rem 2rem;
}

.cookiebar__header__title {
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
}

.cookiebar__header__text {
  margin-top: 1rem;
  font-size: inherit;
  line-height: inherit;
}

.cookiebar__header__actions {
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid transparentize($c-white, 0.5);
}

.cookiebar__header__actions__details {
  font-size: inherit;
  font-weight: 400;
  line-height: inherit;
}

.cookiebar__header__actions__validate {
  padding: 9px;
  font-size: inherit;
  line-height: inherit;
  background: white;
  border-width: 0;
  border-radius: 50%;
  color: $c-almost-black;
}

.cookiebar-action__checkbox.cookiebar-checkbox {
  line-height: 24px;
}

.cookiebar-checkbox__input:checked ~ .cookiebar-checkbox__indicator {
  background-color: $c-main;
}

.cookiebar-checkbox:hover
  .cookiebar-checkbox__input:not([disabled]):checked
  ~ .cookiebar-checkbox__indicator,
.cookiebar-checkbox__input:checked:focus ~ .cookiebar-checkbox__indicator {
  background-color: darken($c-main, 10%);
}

.cookiebar__text {
  a {
    text-decoration: underline;
  }
}

.cookiebar__item__header__title {
  margin-bottom: 0;
  margin-left: 10px;
}

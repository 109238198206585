$border-width: 1px;
$input-font-size: 2rem;
$input-line-height: 3.25;
$input-padding-x: 2rem;

.fieldset {
  padding: 0;
  border-width: 0;
}

.select {
  position: relative;
  margin: 0;
  padding: 0;
  border-width: 0;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: $input-padding-x;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 8px 5px 0;
    border-style: solid;
    border-color: currentColor transparent transparent;
  }
}

.select__input {
  width: 100%;
  padding: 0.25em 0.75em 0.25em 0;
  font-size: 3rem;
  font-weight: 600;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  appearance: none;

  option {
    @include liquid(font-size, 15px, 20px);

    color: $c-text-dark;
    background-color: $c-surface-light;
  }
}

.float-label {
  --fs: #{$input-font-size};
  --lh: #{$input-line-height};

  position: relative;
}

.float-label__label {
  position: absolute;
  top: 50%;
  left: $input-padding-x;
  color: currentColor;
  font-size: var(--fs);
  transform-origin: 0 50%;
  transition: all $transition-duration $easing;
  cursor: text;
  user-select: none; // Prevent text-selection from accidentaly happening when double-clicking into input
  transform: translateY(-50%);
}

.float-label__input {
  width: 100%;
  padding: 27px $input-padding-x 8px $input-padding-x;
  color: currentColor;
  font-size: var(--fs);
  // line-height: var(--lh);
  background-color: $c-input-bg;
  border-width: 0 0 $border-width 0;
  border-color: currentColor;
  border-radius: 0;
  transition: all $transition-duration $easing;

  // Hacky fix to use custom background et text color on autofilled input
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 10em $c-input-bg inset;
    -webkit-text-fill-color: $c-text-light;
    color: $c-text-light;
    transition-duration: 5000s; // Prevent background color flash
  }

  &:focus,
  &.has-value,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    // Prevent chrome from using its own autofill suggestion styling messing up the design.
    // Not sur about the underlying a11y implications...
    &::first-line {
      font-family: $ff-default;
      font-size: var(--fs);
    }

    + .float-label__label {
      cursor: initial;
      transform: translateY(-120%) scale(0.65);
      opacity: 0.5;
    }
  }

  &:invalid:not(.pristine):not(.novalidate),
  &:invalid:not(.pristine):focus:not(.novalidate),
  &:invalid.has-value:not(.novalidate) {
    color: $c-main;
    border-color: $c-main;

    + .label,
    + .float-label__label {
      color: $c-main;
      opacity: 1;
    }
  }

  &.light {
    background-color: $c-surface-light;
    border-color: $c-text-dark;

    // Hacky fix to use custom background et text color on autofilled input
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 10em $c-surface-light inset;
      -webkit-text-fill-color: $c-text-dark;
    }
  }
}

// Non-floating label used for the textarea in the contact form for example
.label {
  --fs: #{$input-font-size};
  --lh: #{$input-line-height};

  font-size: var(--fs);
  line-height: var(--lh);
  transition: all $transition-duration $easing;
}

.textarea {
  display: block;
  width: 100%;
  height: 30rem;
  padding: 0.5em $input-padding-x;
  color: currentColor;
  background-color: $c-input-bg;
  border: $border-width currentColor solid;
  border-radius: 0;
  resize: vertical;
  transition: all $transition-duration $easing;

  &:invalid:not(.pristine),
  &:invalid:not(.pristine):focus,
  &:invalid.has-value {
    color: $c-main;
    border-color: $c-main;

    + .label {
      color: $c-main;
      opacity: 1;
    }
  }
}
